import { Injectable, inject } from '@angular/core';
import {
  Observable,
  combineLatest,
  filter,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
} from 'rxjs';
import { ApiService } from './api.service';
import { Utils } from './utils';
import { TeamsService } from './teams.service';
import { NameValue } from '@codenteam/ui/types/name-value.type';
import { RoutesService } from '@codenteam/core/routes';

interface ContributionsPerDto {
  range$: Observable<{ start: number; end: number }>;
  per?: string;
  teamId$?: Observable<number>;
  runId$: Observable<string>;
  profileId$?: Observable<number>;
  limit?: number;
}
interface ContributionsPerProfileVsOthersDto {
  per?: string;
  teamId$?: Observable<number>;
  runId$: Observable<string>;
  profileId$?: Observable<number>;
}

interface ContributionsPerTeamDto {
  range$: Observable<{ start: number; end: number }>;
  teamId$: Observable<number>;
  limit?: number;
  runId$: Observable<string>;
  per: string;
}
interface ContributionsAtDepthDto {
  depth$: Observable<number>;
  runId$: Observable<string>;
  profileId$?: Observable<number>;
}

@Injectable({ providedIn: 'root' })
export class LinesService {
  constructor(
    private apiService: ApiService,
    private teamsService: TeamsService,
    private routesService: RoutesService
  ) {}
  contributionsPerProfileAtDepth(
    contributionsAtDepth: ContributionsAtDepthDto
  ) {
    return combineLatest([
      contributionsAtDepth.runId$,
      contributionsAtDepth.profileId$ ?? of(null),
      contributionsAtDepth.depth$,
    ])
      .pipe(
        mergeMap(([runId, profileId, depth]) => {
          return this.apiService.contributionAtDepth({
            runId,
            depth,
            contributionFilter: {
              per: 'profile',
              profileId,
            },
          });
        })
      )
      .pipe(
        map((res) => {
          let index = 0;
          let totalPercentageOfShownDirectories = 0;
          let data: NameValue[] = [];
          const total = res.reduce(
            (acc, cur) => acc + (cur.contribution?.[0]?.value || 0),
            0
          );
          res.sort((a, b) => {
            const sumA = a.contribution.reduce(
              (acc, item) => acc + item.value,
              0
            );
            const sumB = b.contribution.reduce(
              (acc, item) => acc + item.value,
              0
            );

            return sumB - sumA;
          });
          res.find((directory) => {
            if (index < 5) {
              const directoryValue = Math.floor(
                ((directory.contribution?.[0]?.value || 0) / total) * 100
              );
              const obj = {
                name: directory.path,
                value: directoryValue,
              };
              data = [...data, obj];
              index++;
              totalPercentageOfShownDirectories += Math.floor(
                ((directory.contribution?.[0]?.value || 0) / total) * 100
              );
            } else {
              return;
            }
          });

          if (totalPercentageOfShownDirectories < 100) {
            const otherObj = {
              name: 'Others',
              value: 100 - totalPercentageOfShownDirectories,
            };
            data = [...data, otherObj];
          } else {
            null;
          }

          return data
            .map((item) => ({
              name: item.name,
              value: item.value,
            }))
            .sort((a, b) => b.value - a.value);
        })
      );
  }

  contributionsPerTeam$(contributionsPerTeam: ContributionsPerTeamDto) {
    return combineLatest([
      contributionsPerTeam.teamId$,
      contributionsPerTeam.runId$,
      contributionsPerTeam.range$,
    ])
      .pipe(
        mergeMap(([teamId, runId, range]) => {
          return this.apiService.contributionsPer(runId, {
            from: range.start,
            to: range.end,
            per: contributionsPerTeam.per,
            limit: contributionsPerTeam.limit,
            teamId,
          });
        })
      )
      .pipe(
        map((res) => {
          return res.map((r) => ({
            id: r.id,
            name: r.name,
            value: r.value,
            color: r.color,
          }));
        })
      );
  }

  contributionPerDayPer$(contributionPerDayPer: {
    range$: Observable<{ start: number; end: number }>;
    per?: string;
    teamId$?: Observable<number>;
    organizationId$?: Observable<number>;
    runId$: Observable<string>;
    profileId$?: Observable<number>;
  }) {
    return combineLatest([
      contributionPerDayPer.teamId$ ?? of(null),
      contributionPerDayPer.profileId$ ?? of(null),
      contributionPerDayPer.organizationId$ ?? of(null),
      contributionPerDayPer.runId$,
      contributionPerDayPer.range$,
    ])
      .pipe(
        mergeMap(([teamId, profileId, organizationId, runId, range]) => {
          const contributionPerDayData = this.apiService.contributionsPerDayPer(
            runId,
            {
              from: range.start,
              to: range.end,
              group: 'day',
              per: contributionPerDayPer.per,
              teamId,
              profileId,
              organizationId,
            }
          );
          return forkJoin([contributionPerDayData, of(range)]);
        })
      )
      .pipe(
        map((res) => {
          if (res[0].length === 0) {
            return [];
          } else {
            return Utils.assignEmptyDaysToZeroValue(res[0], res[1]);
          }
        })
      )
      .pipe(
        map((res) => {
          return Utils.convertDateNameValueToMultiline(res);
        })
      );
  }
  public contributionsPerProfileVsOthers(
    contributionsPerProfileVsOthers: ContributionsPerProfileVsOthersDto
  ) {
    return combineLatest([
      contributionsPerProfileVsOthers.teamId$ ?? of(null),
      contributionsPerProfileVsOthers.profileId$ ?? of(null),
      contributionsPerProfileVsOthers.runId$,
    ]).pipe(
      mergeMap(([teamId, profileId, runId]) => {
        return this.apiService
          .contributionsPer(runId, {
            per: contributionsPerProfileVsOthers.per,
            teamId,
            profileId: null,
          })
          .pipe(
            map((contributionsPer) => ({
              contributionsPer,
              profileId,
            }))
          );
      }),
      map(({ profileId, contributionsPer }) => {
        if (contributionsPer.length > 0) {
          const profile = profileId
            ? contributionsPer.find((value) => value.id === profileId)
            : null;

          const total = Utils.calculateTotalForNameValue(contributionsPer);
          const othersTotal = total - (profile ? profile.value : 0);

          return [
            profile,
            {
              value: othersTotal,
              name: 'Others',
              color: '#334155',
            },
          ];
        } else {
          return [
            {
              value: 100,
              name: 'Others',
              color: '#334155',
            },
          ];
        }
      })
    );
  }
  public getTotalNumberOfLines(runId$: Observable<string>) {
    return runId$.pipe(
      mergeMap((runId) => {
        return this.apiService.countLines(runId);
      })
    );
  }

  /**
   * @param contributionsPer
   * @returns
   */
  public contributionsPer$(contributionsPer: ContributionsPerDto) {
    return combineLatest([
      contributionsPer.teamId$ ?? of(null),
      contributionsPer.profileId$ ?? of(null),
      contributionsPer.runId$,
      contributionsPer.range$,
    ])
      .pipe(
        mergeMap(([teamId, profileId, runId, range]) => {
          return this.apiService.contributionsPer(runId, {
            from: range.start,
            to: range.end,
            per: contributionsPer.per,
            limit: contributionsPer.limit,
            teamId,
            profileId,
          });
        })
      )
      .pipe(
        map((res) => {
          return res.map(Utils.omitTypeName);
        })
      );
  }

  calculateProfileLinesAverageInTeam(
    teamId$: Observable<number>,
    runId$: Observable<string>
  ) {
    const totalLinesPerTeam$ = this.contributionsPer$({
      range$: of({ start: null, end: null }),
      per: 'profile',
      teamId$,
      runId$,
    }).pipe(
      map((res) => {
        return res.reduce((acc, cur) => acc + cur.value, 0);
      })
    );

    // TODO: Remove this, deprecated
    const numberOfProfileInTeam$ = this.teamsService
      .getTeam$(teamId$, runId$)
      .pipe(
        map((res) => {
          return res.profiles.length;
        })
      );

    return combineLatest([totalLinesPerTeam$, numberOfProfileInTeam$]).pipe(
      map(([lines, profiles]) => {
        return Math.round(lines / profiles);
      })
    );
  }
  countLines(runId$: Observable<string>) {
    return runId$.pipe(
      mergeMap((runId) => {
        return this.apiService.countLines(runId);
      })
    );
  }

  contributionsPerProfileVsAllTeams(
    profileId$: Observable<number>,
    runId$: Observable<string>
  ) {
    return combineLatest([profileId$, runId$]).pipe(
      mergeMap(([profileId, runId]) => {
        const total = this.apiService.countLines(runId);
        const profile = this.apiService.contributionsPer(runId, {
          profileId: profileId,
          per: 'profile',
        });
        return forkJoin([total, profile]).pipe(
          map((res) => {
            if (res[1].length > 0) {
              const othersValue = res[0] - res[1][0].value;
              const profile = res[1][0];
              return [
                profile,
                {
                  name: 'Others',
                  value: othersValue,
                  color: '#334155',
                },
              ];
            } else {
              return [
                {
                  name: 'Others',
                  value: res[0],
                  color: '#334155',
                },
              ];
            }
          })
        );
      })
    );
  }

  getHighestContributingTeam$(runId$: Observable<string>) {
    return combineLatest([
      runId$,
      this.contributionsPer$({
        range$: of({
          start: null,
          end: null,
        }),
        per: 'team',
        runId$: runId$,
      }),
    ]).pipe(
      map(([runId, teams]) => {
        const team = Utils.findHighestValue(teams);
        return { runId, team };
      }),
      switchMap(({ runId, team }) =>
        this.apiService.getTeamById(runId, team.id).pipe(
          map((t) => ({
            runId,
            team: {
              ...t,
              ...team,
            },
          }))
        )
      ),
      map(({ runId, team }) => ({
        id: team.id,
        link: this.routesService.absolute(
          this.routesService.teamAnalysis(runId, team.id)
        ),
        value: team.value,
        name: team.name,
        color: team.color,
      }))
    );
  }

  getHighestContributingProfile$(runId$: Observable<string>) {
    return combineLatest([
      runId$,
      this.contributionsPer$({
        range$: of({ start: null, end: null }),
        per: 'profile',
        runId$: runId$,
      }),
    ])
      .pipe(
        map(([runId, profiles]) => {
          const profile = profiles.reduce((maxProfile, profile) =>
            maxProfile.value > profile.value ? maxProfile : profile
          );
          return { runId, profile };
        })
      )
      .pipe(
        switchMap(({ runId, profile }) =>
          this.apiService.getProfileById(runId, profile.id).pipe(
            map((p) => ({
              runId,
              profile: {
                ...p,
                ...profile,
              },
            }))
          )
        ),
        map(({ runId, profile }) => ({
          id: profile.id,
          link: this.routesService.absolute(
            this.routesService.profileAnalysis(
              runId,
              profile.teamId,
              profile.id
            )
          ),
          value: profile.value,
          name: profile.name,
          color: profile.color,
        }))
      );
  }

  getExContribution(runId$: Observable<string>) {
    const exContribution$ = this.contributionsPer$({
      range$: of({ start: null, end: null }),
      per: 'ex',
      runId$: runId$,
    });

    const totalLines$ = this.countLines(runId$);

    const exOwnership$ = combineLatest([totalLines$, exContribution$]).pipe(
      map(([totalLines, exContribution]) => {
        const item = exContribution.find((item: any) => item.name === 'true');
        return item ? Math.round((item.value / totalLines) * 100) : 0;
      })
    );

    return { exContribution$, exOwnership$ };
  }

  getTotalCodeDilution$(runId$: Observable<string>) {
    return combineLatest([
      runId$,
      this.tillLastMonthTeamsContributions$(runId$),
      this.report$(runId$),
    ]).pipe(
      filter(([runId, profiles, report]) => !!report?.code?.total?.team),
      map(([runId, teams, report]) => {
        const afterDilution = teams.reduce((acc, item) => acc + item.value, 0);
        const totalCodeLastMonth = report.code.total.team.reduce(
          (acc: any, ex: any) => acc + ex.value,
          0
        );

        return {
          value: afterDilution - totalCodeLastMonth,
          total: totalCodeLastMonth,
        };
      })
    );
  }

  highestTeamDilution$(runId$: Observable<string>) {
    return combineLatest([
      runId$,
      this.tillLastMonthTeamsContributions$(runId$),
      this.report$(runId$),
    ]).pipe(
      filter(([runId, teams, report]) => !!report?.code?.total?.team),
      map(([runId, teams, report]) => {
        let maxTeam;
        let maxDilution = 0;
        let maxTotal = 0;
        for (const team of teams) {
          for (const totalLastMonth of report.code.total.team) {
            if (totalLastMonth.id === team.id) {
              const dilution = totalLastMonth.value - team.value;
              if (dilution > maxDilution) {
                maxTeam = team;
                maxDilution = -dilution;
                maxTotal = totalLastMonth.value;
              }
              break; // no need to continue iterating once found
            }
          }
        }

        return {
          runId,
          maxTeam,
          maxDilution,
          maxTotal,
        };
      }),
      filter(({ maxTeam }) => !!maxTeam),
      map(({ runId, maxTeam, maxDilution, maxTotal }) => ({
        maxTeam,
        maxDilution,
        maxTotal,
        link: this.routesService.absolute(
          this.routesService.teamAnalysis(runId, maxTeam.id)
        ),
      }))
    );
  }

  highestProfileDilution$(runId$: Observable<string>) {
    return combineLatest([
      runId$,
      this.contributionsPer$({
        range$: of({ start: null, end: Utils.getDefaultDateRange().start }),
        per: 'profile',
        runId$: runId$,
      }),
      this.report$(runId$),
    ]).pipe(
      filter(([runId, profiles, report]) => !!report?.code?.total?.profile),
      map(([runId, profiles, report]) => {
        let maxProfile;
        let maxDilution = 0;
        let maxTotal = 0;
        for (const profile of profiles) {
          for (const totalLastMonth of report.code.total.profile) {
            if (totalLastMonth.id === profile.id) {
              const dilution = totalLastMonth.value - profile.value;
              if (dilution > maxDilution) {
                maxProfile = profile;
                maxDilution = dilution;
                maxTotal = totalLastMonth.value;
              }
            }
          }
        }

        return {
          runId,
          maxProfile,
          maxDilution: -maxDilution,
          maxTotal,
        };
      }),
      filter(({ maxProfile }) => !!maxProfile),
      switchMap(({ runId, maxProfile, maxDilution, maxTotal }) =>
        this.apiService.getProfileById(runId, maxProfile.id).pipe(
          map((p) => ({
            runId,
            maxProfile,
            maxDilution,
            maxTotal,
            link: this.routesService.absolute(
              this.routesService.profileAnalysis(runId, p.teamId, p.id)
            ),
            team: p.team,
          }))
        )
      )
    );
  }

  tillLastMonthTeamsContributions$(
    runId$: Observable<string>
  ): Observable<NameValue[]> {
    return this.contributionsPer$({
      range$: of({ start: null, end: Utils.getDefaultDateRange().start }),
      per: 'team',
      runId$: runId$,
    });
  }

  report$(runId$: Observable<string>): Observable<any> {
    return runId$.pipe(
      switchMap((runId) => this.apiService.getReportClosestTo30DaysAgo(runId))
    );
  }
}
