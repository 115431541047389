export const colorScheme = [
  '#EA580C',
  '#CC9B03',
  '#65A30D',
  '#0891B2',
  '#0284C7',
  '#2563EB',
  '#A855F7',
  '#6D28D9',
  '#EC4899',
  '#52525B',
  '#F43F5E',
  '#DC2626',
];

export const colorSchemeWithOpacity = [
  '#fcd34d',
  '#fef08a',
  '#bef264',
  '#a5f3fc',
  '#a5f3fc',
  '#60a5fa',
  '#d8b4fe',
  '#a78bfa',
  '#f9a8d4',
  '#fb7185',
  '#afafaf',
  '#ef4444',
  '#F37B71',
];

export const notesIconColors = [
  '#D70100', //red Icon
  '#10F500', //green Icon
  '#EC9603', //warning icon
];

export const secondaryColorScheme = [
  '#AAAAAA',
  '#FFFFFF',
  '#6E7E7D',
  '#0F172A',
  '#049BC6',
];
